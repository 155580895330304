(<template>
  <article class="ticket">
    <div class="ticket-info">
      <p class="ticket-id">{{ ticket.id }}</p>
      <p class="ticket-type">{{ ticketType }}</p>
      <p class="ticket-status"
         :class="ticketStatusIcon">{{ ticketStatus }}</p>
      <p class="ticket-assignemnts">{{ ticket.jobAssignments.join(', ') }}</p>
      <p class="ticket-customer">{{ ticket.creatorName }}</p>
      <p class="ticket-department">{{ ticket.departmentNames.join(', ') }}</p>
      <router-link :to="$makeRoute({name: 'BuyerOneTicket', params: {id: ticket.id}})"
                   class="sk-link ticket-link">{{ $gettext('View ticket') }}</router-link>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      ticket: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ticketType() {
        const type = this.ticket.type;
        switch (type) {
          case 'assignment':
            return this.$gettext('Assignment');
          case 'finance':
            return this.$gettext('Finance');
          case 'other':
            return this.$gettext('Other');
          default:
            return '';
        }
      },
      ticketStatus() {
        return this.ticket.status === 'resolved' ? this.$gettext('Finished') : this.$gettext('Open');
      },
      ticketStatusIcon() {
        return this.ticket.status === 'resolved' ? 'ticket__status-finished-icon' : 'ticket__status-open-icon';
      }
    }
  };
</script>

<style scoped>
  p {
    width: 100%;
  }

  .ticket-id {
    max-width: 100px;
  }

  .ticket-type {
    max-width: 220px;
    font-weight: bold;
  }

  .ticket-status {
    max-width: 120px;
    padding-left: 19px;
    background-position: 0 2px;
    background-size: 15px auto;
    background-repeat: no-repeat;
  }

  .ticket-assignemnts {
    max-width: 200px;
    padding-left: 17px;
    background-image: url(~@assets/imgs/undefined_imgs/ticket_assignment_icon_black.svg);
    background-position: 0 2px;
    background-size: 15px auto;
    background-repeat: no-repeat;
  }

  .ticket-customer,
  .ticket-department {
    max-width: 160px;
  }

  .ticket-link {
    margin-left: auto;
  }

  .ticket {
    padding: 20px 0;
    border-top: 1px solid #d3d5de;
    font-size: 14px;
  }

  .ticket-info {
    display: flex;
  }

  .ticket__status-open-icon {
    background-image: url(~@assets/imgs/salita/job_grid/time_icon_orange.svg);
  }

  .ticket__status-finished-icon {
    background-image: url(~@assets/imgs/undefined_imgs/check_icon_green.svg);
  }

  @media (max-width: 768px) {
    .ticket-info {
      flex-direction: column;
    }
  }
</style>
