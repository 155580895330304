// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/ticket_assignment_icon_black.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/salita/job_grid/time_icon_orange.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/undefined_imgs/check_icon_green.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\np[data-v-407f3c8f] {\n  width: 100%;\n}\n.ticket-id[data-v-407f3c8f] {\n  max-width: 100px;\n}\n.ticket-type[data-v-407f3c8f] {\n  max-width: 220px;\n  font-weight: bold;\n}\n.ticket-status[data-v-407f3c8f] {\n  max-width: 120px;\n  padding-left: 19px;\n  background-position: 0 2px;\n  background-size: 15px auto;\n  background-repeat: no-repeat;\n}\n.ticket-assignemnts[data-v-407f3c8f] {\n  max-width: 200px;\n  padding-left: 17px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 0 2px;\n  background-size: 15px auto;\n  background-repeat: no-repeat;\n}\n.ticket-customer[data-v-407f3c8f],\n.ticket-department[data-v-407f3c8f] {\n  max-width: 160px;\n}\n.ticket-link[data-v-407f3c8f] {\n  margin-left: auto;\n}\n.ticket[data-v-407f3c8f] {\n  padding: 20px 0;\n  border-top: 1px solid #d3d5de;\n  font-size: 14px;\n}\n.ticket-info[data-v-407f3c8f] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.ticket__status-open-icon[data-v-407f3c8f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.ticket__status-finished-icon[data-v-407f3c8f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@media (max-width: 768px) {\n.ticket-info[data-v-407f3c8f] {\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n}\n}\n", ""]);
// Exports
module.exports = exports;
