(<template>
  <section class="ticket-statistics-table">
    <div class="ticket-statistics__section-header">
      <h3 class="ticket-statistics__section-title">{{ $gettext('Tickets in this time period') }}</h3>
    </div>
    <div v-if="ticketsListStatisticsLine.length"
         class="ticket-statistics-table__col-name-wrapper">
      <p class="ticket-statistics-table__col-name ticket-statistics-table__col-name--id">{{ $gettext('ID') }}</p>
      <p class="ticket-statistics-table__col-name ticket-statistics-table__col-name--type">{{ $gettext('Type') }}</p>
      <p class="ticket-statistics-table__col-name ticket-statistics-table__col-name--status">{{ $gettext('Status') }}</p>
      <p class="ticket-statistics-table__col-name ticket-statistics-table__col-name--assignmetns">{{ $gettext('Assignments') }}</p>
      <p class="ticket-statistics-table__col-name ticket-statistics-table__col-name--customer">{{ $gettext('Customer') }}</p>
      <p class="ticket-statistics-table__col-name ticket-statistics-table__col-name--department">{{ $gettext('Department') }}</p>
    </div>
    <div v-else
         class="ticket-statistics-table__empty-block">
      <div class="ticket-statistics-table__empty-block-image"></div>
      <h4 class="ticket-statistics-table__empty-block-subtitle">{{ $gettext('No tickets') }}</h4>
      <p class="ticket-statistics-table__empty-block-text">{{ noTicketsText }}</p>
    </div>
    <statistic-line-item v-for="(ticket, index) in ticketsListStatisticsLine"
                         :key="index"
                         :ticket="ticket" />
    <div class="ticket-statistics-table__pagination-wrapper">
      <items-amount v-if="ticketsListStatisticsLine.length"
                    @amountchanged="setPages" />
      <sk-pagination v-if="+pages && +pages > 1"
                     :current="+page"
                     :total="+pages"
                     @prev="toPrevPage"
                     @next="toNextPage"
                     @newpage="toNewPage" />
    </div>
  </section>
</template>)

<script>
  import {mapState} from 'vuex';
  import StatisticLineItem from '@/pages/BuyerPages/ticket_system_pages/statistics/StatisticsLineItem';
  import ItemsAmount from '@/components/shared_components/ItemsAmount';

  export default {
    components: {
      'statistic-line-item': StatisticLineItem,
      'items-amount': ItemsAmount
    },
    data() {
      return {
        currentPage: this.$route.query.page || 1,
      };
    },
    computed: {
      ...mapState('TicketSystemStore', {
        ticketsListStatisticsLine: (state) => state.ticketsStatisticsLine.tickets || [],
        pages: (state) => state.ticketsStatisticsLine.pages || 1,
        page: (state) => state.ticketsStatisticsLine.page || 1,
      }),
      noTicketsText() {
        return this.$gettext('You don\'t have any tickets yet. Once you have at least one ticket, you\'ll see statistics here.');
      },
      filterItems() { return this.$store.state.filterItemsAmount; },
    },
    methods: {
      setPages() {
        const queryParams = {
          page: this.currentPage,
          items: this.filterItems
        };
        this.$router.push(this.$makeRoute({name: 'BuyerTicketsStatistics', query: queryParams}));
      },
      toPrevPage() {
        this.currentPage -= 1;
        this.setPages();
      },
      toNewPage(page) {
        this.currentPage = +page;
        this.$store.state.TicketSystemStore.ticketsStatisticsLine.page = +page;
        this.setPages();
      },
      toNextPage() {
        this.currentPage += 1;
        this.setPages();
      },
    },
    beforeMount() {
      if (this.$route.query.items) this.$store.commit('setFilterItemsAmount', this.$route.query.items);
    }
  };
</script>

<style scoped>
.ticket-statistics__section-title {
  color: var(--black-700);
  font-size: 18px;
}

.ticket-statistics-table {
  margin-top: 24px;
  padding: 20px 30px 15px 30px;
  background-color: var(--col-white);
}

.ticket-statistics-table__col-name-wrapper {
  display: flex;
  padding-top: 20px;
}

.ticket-statistics-table__col-name {
  overflow: hidden;
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  text-overflow: ellipsis;
}

.ticket-statistics-table__col-name--id {
  max-width: 100px;
}

.ticket-statistics-table__col-name--type {
  max-width: 220px;
}

.ticket-statistics-table__col-name--status {
  max-width: 120px;
}

.ticket-statistics-table__col-name--assignmetns {
  max-width: 200px;
}

.ticket-statistics-table__col-name--customer,
.ticket-statistics-table__col-name--department {
  max-width: 160px;
}

/* empty table */
.ticket-statistics-table__empty-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
}

.ticket-statistics-table__empty-block-image {
  width: 96px;
  height: 96px;
  background-image: url(~@assets/imgs/undefined_imgs/dashboard_icon.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.ticket-statistics-table__empty-block-subtitle {
  margin: 24px 0 8px 0;
  font-size: 20px;
}

.ticket-statistics-table__empty-block-text {
  width: 400px;
  color: var(--black-500);
  text-align: center;
}

.ticket-statistics-table__pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
</style>
