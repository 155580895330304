(<template>
  <section class="items-amount">
    <div class="items-amount__wrapper">
      <p class="items-amount__text">{{ $gettext('Results per page:') }}</p>
      <sk-select :preselected-value="chosenValue"
                 :items-list="items"
                 class="items-amount__select"
                 @csvaluechanged="changeAmount" />
    </div>
  </section>
</template>)

<script>
  export default {
    data() {
      return {
        items: [
          {id: '10', name: '10'},
          {id: '20', name: '20'},
          {id: '30', name: '30'}
        ]
      };
    },
    computed: {
      chosenValue() {
        return this.$store.state.filterItemsAmount;
      }
    },
    methods: {
      changeAmount(value) {
        this.$store.commit('setFilterItemsAmount', value);
        this.$emit('amountchanged');
      }
    }
  };
</script>

<style>
  .items-amount__select {
    width: 30px;
    color: #000;
    font-size: 12px;
  }

  .items-amount__select .sk-select {
    padding-right: 15px;
    border: 0;
    text-align: right;
  }

  .items-amount__select .sk-select.is-opened {
    box-shadow: none;
  }

  .items-amount__select .sk-select__value {
    padding: 0;
    line-height: 35px;
  }

  .items-amount__select .sk-select__arrow {
    width: 15px;
    height: 35px;
  }

  .items-amount__select .sk-select__arrow::after {
    right: 2px;
    background-color: #000;
  }

  .items-amount__select .sk-select__arrow::before {
    left: 2px;
    background-color: #000;
  }

  .items-amount__select .sk-select__list {
    top: auto;
    right: 0;
    bottom: 100%;
    left: auto;
    width: auto;
  }
</style>

<style scoped>
  .items-amount {
    flex-shrink: 0;
  }

  .items-amount__wrapper {
    display: flex;
    align-items: center;
    color: #646d8c;
    font-size: 12px;
  }

  .items-amount__text {
    padding-right: 5px;
    text-align: right;
  }

  @media (max-width: 767px) {
    .items-amount__select {
      margin-bottom: 0;
    }
  }
</style>
